<template>
    <custom-table
        :filter_settings="PbnFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :links="links"
        :loading="loadingTable || !items"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/pbn-pages/pbn-table-header-items'
import { PbnFilters } from '@/helpers/filters-settings/pbn-filters'
import { FilterItems, Filters } from '@/helpers/constants/pbn-pages/filters'
import { toolbarSettings } from '@/helpers/constants/pbn-pages/toolbar-settings'

import { mapSeoListFields } from '@/store/modules/seo/seo-list'
import { createNamespacedHelpers } from 'vuex'

import {
    ACTION_GET_PBN_LIST
} from '@/store/modules/seo/seo-list/action-types'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList');

export default {
    name: 'Pbn',
    mixins: [contentBaseMixin],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-pbn',
            filters: {}
        }
    },
    computed: {
        ...mapSeoListFields([
            'pbn',
            'loading'
        ]),
        headerItems() {
            return headerItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        PbnFilters() {
            return PbnFilters
        },
        filterItems() {
            return FilterItems
        },
        items() {
            return this.pbn
        },
        loadingTable() {
            return this.loading
        },
        links() {
            return []
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(async function (val) {
            if (this.$route.name !== 'posts-list-pbn') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            await this.getList(val)
        }, 50)
    },
    mounted() {
        if (Object.values(this.$route.query).length !== 0) {
            this.filters = { ...this.$route.query }
        } else {
            this.filters = { ...Filters }
        }
        this.getList(this.$route.query)
    },
    methods: {
        ...mapSeoListActions([
            ACTION_GET_PBN_LIST
        ]),
        getList(query = {}) {
            this[ACTION_GET_PBN_LIST]({ ...query })
        }
    }
}
</script>

<style scoped>

</style>
