export const PbnFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        search: true
    },
    lists: {
        fieldsList: [
            {
                value: 'domain_url',
                text: 'Domain url'
            }
        ],
        statusList: [
            {
                value: 'all',
                text: 'All'
            },
            {
                value: 'live',
                text: 'Live'
            },
            {
                value: 'dead',
                text: 'Dead'
            }
        ]
    }
}
