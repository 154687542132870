export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width:40px',
        classes: ''
    },
    {
        sort_by: 'index',
        styles: 'width:40px',
        classes: ''
    },
    {
        classes: 'custom-table__row-donor',
        styles: 'justify-content: flex-start; text-align: left; width: 25%',
        sort_by: 'domain',
        title: 'Domain'
    },
    {
        classes: 'custom-table__row-ip',
        styles: 'justify-content: center; width: 150px; text-align: left; width: 25%',
        sort_by: 'ip',
        title: 'IP'
    },
    {
        classes: 'custom-table__row-anchor',
        styles: 'justify-content: center;  text-align: left; width: 300px;',
        sort_by: 'ns',
        title: 'Ns',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-pinged',
        styles: 'justify-content: center; text-align: center; text-align: left',
        sort_by: 'pinged_at',
        title: 'Pinged'
        // hide_sort: true
    },
    {
        classes: 'custom-table__row-status',
        sort_by: 'ping_status',
        styles: 'justify-content: center',
        title: 'Status'
    }
]
